document.addEventListener('DOMContentLoaded', function(){

	let burger = document.querySelector('.h__burger'),
			h = document.querySelector('.h');

	burger.addEventListener('click', function () {
		
		if ( h.classList.contains('h--opened') ) {
			h.classList.remove('h--opened');
			burger.setAttribute('aria-expanded', false);
			document.body.style.overflow = '';
		} else {
			h.classList.add('h--opened');
			burger.setAttribute('aria-expanded', true);
			document.body.style.overflow = 'hidden';
		}
	});


	// load more news
	const loadMoreBtn = document.querySelector('.item-load-more');
	if (loadMoreBtn) {
		let loadedItems = [];

		loadMoreBtn.addEventListener('click', function(){
			let state = loadMoreBtn.textContent;

			// for first loading
			if ( loadMoreBtn.hasAttribute('data-id') ) {
				loadedItems.push(loadMoreBtn.dataset.id);
				loadMoreBtn.removeAttribute('data-id');
			}

			loadMoreBtn.disabled = true;
			loadMoreBtn.textContent = loadMoreBtn.dataset.wait;

			fetch('/data/nextItem.json', {
				method: 'post',
				body: loadedItems.toString(),
				credentials: 'include',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
				},
			}).then(response => {
				return response.json();
			}).then(resp => {
				loadMoreBtn.disabled = false;
				loadMoreBtn.textContent = state;

				loadedItems.push(resp.id);
				loadMoreBtn.closest('.item-load-more-wrp')
					.insertAdjacentHTML('beforeBegin', resp.content);

				if (resp.isLast) {
					loadMoreBtn.remove();
				}
			});

		});
	}
});